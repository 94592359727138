@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Montserrat:wght@400;500;600;700&display=swap");

@import "tailwindcss/base";
@import "./base.css";

@import "tailwindcss/components";
@import "./components/slider.css";
@import "./components/app.css";

@import "tailwindcss/utilities";
@import "./utilities.css";

body {
  @apply text-gray-100;
}

.ymaps[class*="search__suggest"] {
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 155%;
  font-family: "Comfortaa", sans-serif;
  text-transform: uppercase;
}

.ymaps[class*="suggest-item"] {
  padding: 4px 10px;
}
