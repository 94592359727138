@tailwind components;

@layer components {
  .h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    text-transform: uppercase;
    font-family: "Comfortaa", sans-serif;
  }
  .h2 {
    font-weight: 500;
    font-size: 17px;
    line-height: 155%;
    text-transform: uppercase;
    font-family: "Comfortaa", sans-serif;
  }
  .h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
  }
  .h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
  }
  .h5 {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
  }
  .h6 {
    font-weight: 400;
    font-size: 13px;
    line-height: 130%;
    text-transform: uppercase;
    font-family: "Comfortaa", sans-serif;
  }
  .h7 {
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    text-transform: uppercase;
    font-family: "Comfortaa", sans-serif;
  }
  .h8 {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    text-transform: uppercase;
    font-family: "Comfortaa", sans-serif;
  }
  .text1 {
    font-weight: 400;
    font-size: 12px;
    line-height: 155%;
  }
  .text2 {
    font-weight: 500;
    font-size: 10px;
    line-height: 140%;
  }
  .text3 {
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
  }
  .text4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 155%;
  }
  .link:hover {
    text-decoration: underline;
  }
  .button-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }
  .input {
    padding: 0 20px;
    height: 50px;
    border: 1px solid #cbcbcb;
    border-radius: 10px;
    font-weight: 500;
    font-size: 17px;
    line-height: 155%;
    font-family: "Comfortaa", sans-serif;
    text-transform: uppercase;
    outline: none;
  }
  .input::placeholder {
    color: #5C5C5C;
  }
  .wrap {
    padding: 30px 16px;
  }
  .grid-10 {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 20px;
  }
  .grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  @media screen(md) {
    .h1 {
      font-size: 30px;
    }
    .h2 {
      font-size: 20px;
      line-height: 160%;
    }
    .h6 {
      font-size: 15px;
      line-height: 120%;
    }
    .h7 {
      font-size: 17px;
      line-height: 140%;
    }
    .text1 {
      font-size: 14px;
    }
    .text2 {
      font-size: 12px;
    }
    .text4 {
      font-size: 14px;
    }
    .button-text {
      font-size: 14px;
      line-height: 17px;
    }
    .wrap {
      padding: 40px 100px;
    }
    .input {
      padding: 0 22px;
      height: 60px;
      font-weight: 400;
      font-size: 15px;
      line-height: 120%;
    }
  }

  @media screen(xl) {
    .h1 {
      font-weight: 400;
      font-size: 50px;
    }
    .h2 {
      font-size: 30px;
      line-height: 150%;
    }
    .h5 {
      font-size: 12px;
      line-height: 15px;
    }
    .h6 {
      font-weight: 600;
      font-size: 17px;
      line-height: 19px;
    }
    .h7 {
      font-size: 20px;
      line-height: 120%;
    }
    .h8 {
      font-weight: 600;
      font-size: 13px;
      line-height: 120%;
    }
    .text1 {
      font-size: 18px;
    }
    .text2 {
      font-size: 15px;
    }
    .text4 {
      font-weight: 400;
      font-size: 17px;
      line-height: 150%;
    }
    .button-text {
      font-size: 16px;
      line-height: 20px;
    }
    .wrap {
      padding: 60px 100px;
    }
    .input {
      font-weight: 600;
      font-size: 17px;
      line-height: 19px;
    }
  }
  .transition {
    transition: 0.2s all;
  }
  .rotate {
    animation: rotate 20s linear infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
  }
}
